import { Box, InputAdornment } from '@mui/material';
import { FC } from 'react';

import { InputField } from './InputField';
import { IInputFieldProps } from './InputField.d';

interface IInputEndAdornment extends IInputFieldProps {
  adornment: string;
  [key: string]: unknown;
}

const InputEndAdornment: FC<IInputEndAdornment> = ({ adornment, ...props }) => {
  return (
    <InputField
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Box component="span" color={({ palette }) => palette.grey[500]}>
              {adornment}
            </Box>
          </InputAdornment>
        ),
      }}
      fullWidth
      {...props}
    />
  );
};

export { InputEndAdornment };
