import { Box, Button, Menu, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { IconChevronDown, IconFilter } from '@tabler/icons';
import { FC, FormEvent, useContext, useRef, useState } from 'react';

import { SearchBox } from '../../components/SearchBox';
import { Select } from '../../components/Select';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext'; //eslint-disable-line
import { IReportTableHeaderProps } from './PayoutReportDetail.d';
import { accountTypeFilter, statusFilter } from './statusDefinition';

const TableHeader: FC<IReportTableHeaderProps> = ({
  applyFilter,
  handleSearch,
  control,
  download,
  clearFilters,
  clearSearch,
  handleSetPayment,
  selection,
}) => {
  const menuAnchor = useRef<HTMLButtonElement>(null);
  const filterAnchor = useRef<HTMLButtonElement>(null);
  const anchorWidth = menuAnchor?.current && menuAnchor.current.offsetWidth;
  const [openMenu, setOpenMenu] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);
  const toggleFilters = () => setOpenFilters(!openFilters);
  const { userData } = useContext(authContext);
  const financialRole = havePermission(userData?.roles ?? [], ['financial', 'superAdmin']);
  const isManualPayments = process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true';

  const handleFilters = (event: FormEvent<HTMLFormElement>) => {
    applyFilter(event);
    toggleFilters();
  };

  const onClearFilters = () => {
    clearFilters();
    toggleFilters();
  };

  const onSetPayment = (newStatus: string) => {
    handleSetPayment(newStatus);
    setOpenMenu(false);
  };

  return (
    <Box
      p={2}
      display="flex"
      alignItems="center"
      gap={2}
      justifyContent="space-between"
      flexWrap="wrap"
      sx={{ borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}` }}
    >
      <Box
        width={{ xs: '100%', md: '50%' }} //eslint-disable-line
      >
        <SearchBox onSubmit={(value) => handleSearch(value)} onClear={clearSearch} />
      </Box>
      <Box gap={2} display="flex" flexWrap="wrap">
        <Button
          variant="outlined"
          endIcon={<IconChevronDown />}
          sx={{ fontSize: '14px' }} //eslint-disable-line
          ref={menuAnchor}
          onClick={toggleMenu}
          disabled={selection.length === 0 || !financialRole}
        >
          Set payment status
        </Button>
        <Button
          variant="outlined"
          startIcon={<IconFilter />}
          sx={{ fontSize: '14px' }}
          ref={filterAnchor}
          onClick={toggleFilters}
        >
          Filter
        </Button>
        {download}
        {!isManualPayments && (
          <Button
            variant="contained"
            sx={{ fontSize: '14px' }} //eslint-disable-line
            onClick={() => onSetPayment('PAY')}
            disabled={
              process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true' ||
              selection.length === 0 ||
              !financialRole
            }
          >
            Pay
          </Button>
        )}
      </Box>
      <Menu
        open={openMenu}
        anchorEl={menuAnchor.current}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 2, width: anchorWidth } }}
        onClose={toggleMenu}
      >
        <MenuItem onClick={() => onSetPayment('PAID_OFFLINE')} sx={{ py: 1.5 }}>
          Set as paid
        </MenuItem>
        <MenuItem onClick={() => onSetPayment('PENDING')} sx={{ py: 1.5 }}>
          Set as pending
        </MenuItem>
      </Menu>
      <Popover
        role="region"
        aria-label="filters"
        open={openFilters}
        onClose={toggleFilters}
        anchorEl={filterAnchor.current}
        PaperProps={{ sx: { borderRadius: 2, boxShadow: 2, p: 3, width: 316 } }}
      >
        <Typography variant="caption2">Filter options</Typography>
        <form onSubmit={handleFilters}>
          <Stack my={3} gap={4}>
            <Select label="Status" name="status" control={control} options={statusFilter} />
            <Select
              label="Account type"
              name="accountType"
              control={control}
              options={accountTypeFilter}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button size="medium" variant="text" color="secondary" onClick={onClearFilters}>
              Clear
            </Button>
            <Button type="submit" sx={{ fontSize: '14px', ml: 2 }} variant="contained">
              Apply
            </Button>
          </Stack>
        </form>
      </Popover>
    </Box>
  );
};
export { TableHeader };
