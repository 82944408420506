import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC } from 'react';

import { IEmptyFeesConfirmationModal } from './EmptyFeesConfirmationModal.d';

const EmptyFeesConfirmationModal: FC<IEmptyFeesConfirmationModal> = ({
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: 4,
          p: 2,
        },
      }}
    >
      <IconButton
        sx={{ color: 'text.primary', position: 'absolute', right: 10, top: 10 }}
        onClick={onClose}
      >
        <IconX />
      </IconButton>
      <DialogTitle sx={{ fontWeight: 'bold', mb: 2, variant: 'subtitle2' }}>
        Warning 0% fees
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to continue saving the changes with Asset Management fee, Upfront fee or
          Placement fee on 0% ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Discard
        </Button>
        <Button color="primary" onClick={onSubmit} variant="contained">
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EmptyFeesConfirmationModal };
