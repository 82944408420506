import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { FC } from 'react';

import { ICommissionsUpdatedModalProps } from '../Commissions.d';

const CommissionsUpdatedModal: FC<ICommissionsUpdatedModalProps> = ({
    openCommissionsUpdatedModal,
    setOpenCommissionsUpdatedModal,
    totalBrokerErrors,
    totalBrokerUpdated
}) => {
  return (
    <Dialog
        open={openCommissionsUpdatedModal}
        onClose={() => setOpenCommissionsUpdatedModal(false)}
        aria-labelledby="modal-validate-closing"
        aria-describedby="modal-validate-closing"
        disableAutoFocus
        PaperProps={{
            sx: {
            borderRadius: 3,
            boxShadow: 4,
            p: 4,
            pt: 6,
            },
        }}
      >
        <IconButton
          color="primary"
          onClick={() => setOpenCommissionsUpdatedModal(false)}
          sx={{ position: 'absolute', right: 10, top: 10 }}
        >
          <IconX />
        </IconButton>
        <Typography variant="h3" fontWeight="bold" mb={2}>Commissions updated result</Typography>
        <Typography variant="body1" mt={1}>
          Total updated: {totalBrokerUpdated}
        </Typography>
        <Typography variant="body1" mt={1}>
          Total errors: {totalBrokerErrors}
        </Typography>
        <Stack sx={{ mt: 5 }}>
          <Button sx={{ mb: 2 }} color="primary" variant="contained"
          fullWidth={false} onClick={() => setOpenCommissionsUpdatedModal(false)}>
            Close
          </Button>
        </Stack>
      </Dialog>
  );
};

export { CommissionsUpdatedModal };