/* eslint-disable sonarjs/no-duplicate-string */
const statusDef: { [key: string]: string } = {
  blacklisted: 'Blacklisted',
  failed: 'Failed',
  not_payable: 'Not payable',
  paid: 'Paid',
  paid_offline: 'Paid offline',
  payment_in_process: 'In process',
  pending: 'Pending',
};

const paymentMethodDef: { [key: string]: string } = {
  ach: 'ACH',
  crypto: 'Crypto',
  manual: 'Manual',
  wire: 'Wire',
};

const statusFilter = [
  { label: 'Failed', value: 'failed' },
  { label: 'Not payable', value: 'not_payable' },
  { label: 'Paid', value: 'paid' },
  { label: 'Paid offline', value: 'paid_offline' },
  { label: 'In process', value: 'payment_in_process' },
  { label: 'Pending', value: 'pending' },
];
const paymentMethodFilter = [
  { label: 'Crypto', value: 'crypto' },
  { label: 'Manual', value: 'manual' },
  { label: 'Wire', value: 'wire' },
];

const accountTypeFilter = [
  { label: 'Wallet', value: 'WALLET' },
  { label: 'Wire', value: 'WIRE' },
];

export { accountTypeFilter, paymentMethodDef, paymentMethodFilter, statusDef, statusFilter };
