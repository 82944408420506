import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';

import payouts from '../../../assets/payouts/payouts.svg'; //eslint-disable-line
import { Tooltip } from '../../../components/Tooltip'; //eslint-disable-line
import { INavigationProps } from './Payouts.presenter.d';

const EmptyStateReport: FC<INavigationProps> = ({ goToCreateReport, disabled }) => {
  return (
    <Box textAlign="center" py={6}>
      <img src={payouts} alt="empty payouts list" />
      <Typography
        sx={{ fontFamily: 'Sora', fontSize: 24, lineHeight: '32px', my: 2, textAlign: 'center' }}
        data-testid="test-payout-empty-state-text"
      >
        Here you can create a payout report to see how the dividends are going to be distributed to
        the investors.
      </Typography>
      <Box>
        <Tooltip title={disabled ? 'The contract must be paused before creating it' : ''}>
          <span>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={goToCreateReport}
              data-testid="test-create-payout-report-button"
            >
              Create payouts report
            </Button>
            {disabled && <Typography> Pause smart contract first!</Typography>}
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export { EmptyStateReport };
