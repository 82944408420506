import { Box, Button, CircularProgress, Typography } from '@mui/material';// eslint-disable-line

import { ChangeEvent, FC, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useOutletContext } from 'react-router-dom';

import { IUpdateCommisionProps } from '../Commissions.d';
import { InputEndAdornment } from '../../../../components/InputField';
import { InfoIcon } from '../../../../components/InfoIcon';
import { Gray } from '../../../../theme/tokens/colors';
import { commissionsAreas } from '../../../../api/commissions';
import { IProjectOutletContext } from '../../ProjectDetail.d';
import { PROJECT_STATUSES } from '../../../../helpers/constants';

const UpdateCommission: FC<IUpdateCommisionProps> = ({
  handlerCommissionChanged,
  setProjectCommission,
}) => {
    const area = commissionsAreas.updateCommissions;
    const [value, setValue] = useState<string>('0');
    const { promiseInProgress } = usePromiseTracker({ area });
    const { status: projectStatus } = useOutletContext<IProjectOutletContext>();

    const disableChangeCommissions =
    [
      PROJECT_STATUSES.CANCELED,
      PROJECT_STATUSES.ARCHIVED,
      PROJECT_STATUSES.PROJECT_COMPLETED,
      PROJECT_STATUSES.CREATING_TOKENS,
      PROJECT_STATUSES.DEVELOPMENT_COMPLETED,
      PROJECT_STATUSES.TOKENS_DELIVERED,
    ].includes(projectStatus as PROJECT_STATUSES);

    const onChangeHandleDecimal = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const UP_TO_100_REGEX = /^$|^(?:\d{1,2}(?:\.\d{0,2})?|100(?:\.0{0,2})?)$/g
        const isValidValue = (regex: RegExp) => regex.test(value);

        const isValid = isValidValue(UP_TO_100_REGEX);

        if (isValid) {
            const amount = Number(value.replaceAll(',', ''));
            setValue(value);
            setProjectCommission(amount);
            return value;
        }
    };

    return (
        <>
            <Box
                display="flex"
                gap={2}
                gridTemplateColumns={{lg: 'repeat(2, 1fr)', xs: '1fr'}}
                width={300}
            >
                <InputEndAdornment
                    label={
                        <Typography>
                            Commission
                            <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                                <Typography variant="caption" color={Gray[100]}>
                                    Apply this commission for all brokers
                                </Typography>
                            </InfoIcon>
                        </Typography>
                    }
                    adornment="%"
                    inputProps={{
                        onChange: (e: ChangeEvent<HTMLInputElement>) => onChangeHandleDecimal(e),
                    }}
                    value={value}
                    disabled={promiseInProgress || disableChangeCommissions}
                />
                <Box sx={{ position: 'relative' }}>
                    <Button
                        variant="outlined"
                        component="span"
                        onClick={handlerCommissionChanged}
                        sx={{
                            borderRadius: '100px',
                            fontSize: 14,
                            height: '40px',
                            marginTop: 1,
                            py: '1px',
                        }}
                        style={{ pointerEvents: promiseInProgress || disableChangeCommissions ? 'none' : 'auto' }}
                        disabled={disableChangeCommissions || promiseInProgress}
                    >
                        Apply
                    </Button>
                    
                </Box>
                {promiseInProgress && (
                    <CircularProgress sx={{ml: 25, position: 'absolute'}} size={30} color="secondary" />
                )}
            </Box>
        </>
    );
};

export { UpdateCommission };