/* eslint-disable sort-keys */
import { format } from 'date-fns';

import { IInvestor } from '../../components/PageContent';
import { formatToken } from '../../helpers'; // eslint-disable-line
import { IAccountDataWallet, IAccountDataWire, IReportInvestor, IReportInvestorResponse } from './payouts.d';

const decoratePaymentStatus = (investor: IReportInvestorResponse): string => {
  if (!investor.payoutAccount && investor.paymentStatus === 'PENDING') {
    return process.env.REACT_APP_SWITCH_MANUAL_PAYMENTS === 'true' ? 'pending' : 'not_payable';
  }
  if (investor.walletStatus === 'BLACKLISTED' && investor.paymentStatus === 'PENDING') {
    return 'blacklisted';
  }
  return investor.paymentStatus.toLowerCase();
};

const decoratePayoutsReportInvestor = (
  data: IReportInvestorResponse[],
  users: IInvestor[]
): IReportInvestor[] => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return data.map((investor, i) => {
    const accountDataWire = investor.accountData as IAccountDataWire;
    const accountDataWallet = investor.accountData as IAccountDataWallet;
    return {
      id: investor.userId,
      name: users[i].legalName,
      payment_date: investor.dateOfPayment
        ? format(new Date(investor.dateOfPayment), 'MM/dd/yyyy')
        : '',
      accountType: investor.accountType  || '',
      payout_account: investor.payoutAccount || '',
      status: decoratePaymentStatus(investor),
      tokens: formatToken(investor.tokens),
      total: investor.amountToBePaid,
      accountNumber: accountDataWire?.accountNumber || '',
      routingNumber: accountDataWire?.routingNumber || '',
      iban: accountDataWire?.iban || '',
      billingDetailsName: accountDataWire?.billingDetails?.name || '',
      billingDetailsCity: accountDataWire?.billingDetails?.city || '',
      billingDetailsCountry: accountDataWire?.billingDetails?.country || '',
      billingDetailsLine1: accountDataWire?.billingDetails?.line1 || '',
      billingDetailsLine2: accountDataWire?.billingDetails?.line2 || '',
      billingDetailsDistrict: accountDataWire?.billingDetails?.district || '',
      billingDetailsPostalCode: accountDataWire?.billingDetails?.postalCode || '',
      bankAddressBankName: accountDataWire?.bankAddress?.bankName || '',
      bankAddressCity: accountDataWire?.bankAddress?.city || '',
      bankAddressCountry: accountDataWire?.bankAddress?.country || '',
      bankAddressline1: accountDataWire?.bankAddress?.line1 || '',
      bankAddressLine2: accountDataWire?.bankAddress?.line2 || '',
      bankAddressDistrict: accountDataWire?.bankAddress?.district || '',
      cryptoAddress: accountDataWallet?.cryptoAddress || '',
      addressTag: accountDataWallet?.addressTag || '',
      chain: accountDataWallet?.chain || '',
      currency: accountDataWallet?.currency || '',
    };
  });
};

export { decoratePayoutsReportInvestor };
