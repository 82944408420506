// eslint-disable-next-line simple-import-sort/imports
import { DesktopDatePicker } from '@mui/lab';
import { Box, Divider, Grid, Theme, Typography, useTheme } from '@mui/material';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { areas } from '../../../api/blockchain/areas';
import { projectAreas } from '../../../api/projects';
import { AttachedReports } from '../../../components/AttachedReports/AttachedReports';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { FileUploadButton } from '../../../components/FileUploadButton/FileUploadButton';
import { InfoIcon } from '../../../components/InfoIcon/InfoIcon';
import { CalendarIcon, InputEndAdornment, InputField } from '../../../components/InputField';
import { IOption, Select } from '../../../components/Select';
import { PROJECT_STATUSES } from '../../../helpers/constants';
import { Gray } from '../../../theme/tokens/colors';
import {
  MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME,
  MAX_INVESTMENT_PERCENTAGE_LOANS,
} from '../ProjectDetail.d';

import { IFinancialProps } from './Financial.d';

const distributions: IOption[] = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Bimonthly',
    value: 'bimonthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Annual',
    value: 'annual',
  },
  {
    label: 'Biannual',
    value: 'biannual',
  },
  {
    label: 'End of Project',
    value: 'end-of-project',
  },
];

const Financial: FC<IFinancialProps> = ({
  status,
  canEdit,
  financialRole,
  values,
  payoutsMetrics,
  dolarsCleaned,
  onChangeHandleDecimal,
  onChangeHandleDolars,
  onUploadReports,
  showFinancialMetrics,
  handleMoreThan100Percent,
  onRemoveReport,
  reports,
}) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const [reportDate, setReportDate] = useState(Date.now());
  const isReportableProject = [
    PROJECT_STATUSES.TOKENS_DELIVERED,
    PROJECT_STATUSES.DEVELOPMENT_COMPLETED,
    PROJECT_STATUSES.PROJECT_COMPLETED,
  ].includes(status as PROJECT_STATUSES);

  const onHandleReport = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return onUploadReports(e, reportDate);
    },
    [onUploadReports, reportDate]
  );
  const theme: Theme = useTheme();

  return (
    <>
      <Typography variant="h1" pb={3}>
        General
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2,1fr)' }} //eslint-disable-line
        gap={4}
      >
        <InputEndAdornment
          label={
            <Typography>
              Minimum investment
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Minimum allowed investment.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="USD"
          inputProps={{
            ...register('financialInformation.minimumInvestment', {
              setValueAs: dolarsCleaned,
            }),
          }}
          onChange={onChangeHandleDolars}
          value={values['financialInformation.minimumInvestment']}
          error={!!errors?.financialInformation?.minimumInvestment}
          helperText={errors?.financialInformation?.minimumInvestment?.message}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Maximum investment percentage
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  {MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME}% for Development and Incoming,{' '}
                  {MAX_INVESTMENT_PERCENTAGE_LOANS}% for Loans.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.maximumInvestmentPercentage'),
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              onChangeHandleDecimal(e, watch('projectType')),
          }}
          value={values['financialInformation.maximumInvestmentPercentage']}
          error={!!errors?.financialInformation?.maximumInvestmentPercentage}
          helperText={errors?.financialInformation?.maximumInvestmentPercentage?.message}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Total targeted investment
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Targeted amount needed to fund the real estate project.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="USD"
          error={!!errors?.financialInformation?.totalTargetedInvestment}
          helperText={errors?.financialInformation?.totalTargetedInvestment?.message}
          inputProps={{
            ...register('financialInformation.totalTargetedInvestment', {
              setValueAs: dolarsCleaned,
            }),
          }}
          onChange={onChangeHandleDolars}
          value={values['financialInformation.totalTargetedInvestment']}
          disabled={!canEdit}
        />

        <InputEndAdornment
          label={
            <Typography variant="inherit">
              Percent overage allowed{' '}
              <Typography variant="inherit" component="span" color="text.disabled">
                (OPTIONAL)
              </Typography>
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Percentage over total targeted amount. Once total targeted investment is reached
                  we can allow an extra amount if it is allowed.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.percentOverage'),
            onChange: onChangeHandleDecimal,
          }}
          error={!!errors?.financialInformation?.percentOverage}
          helperText={errors?.financialInformation?.percentOverage?.message}
          value={values['financialInformation.percentOverage'] || ''}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Total return on investment
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Estimated total return of the project, includes interest, capital gains,
                  dividends, and distributions realized over a period.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.totalReturnInvestment'),
            onChange: handleMoreThan100Percent,
          }}
          error={!!errors?.financialInformation?.totalReturnInvestment}
          helperText={errors?.financialInformation?.totalReturnInvestment?.message}
          value={values['financialInformation.totalReturnInvestment'] || ''}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Net IRR on investment
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  ROI: Estimated annual return of the project.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.annualInvestment'),
            onChange: onChangeHandleDecimal,
          }}
          error={!!errors?.financialInformation?.annualInvestment}
          helperText={errors?.financialInformation?.annualInvestment?.message}
          value={values['financialInformation.annualInvestment'] || ''}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography variant="inherit">
              Annual cash on cash return/dividends{' '}
              <Typography variant="inherit" component="span" color="text.disabled">
                (OPTIONAL)
              </Typography>
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Annual return the investor made on the property in relation to the amount of
                  mortgage paid during the same year.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.annualCash'),
            onChange: onChangeHandleDecimal,
          }}
          error={!!errors?.financialInformation?.annualCash}
          helperText={errors?.financialInformation?.annualCash?.message}
          value={values['financialInformation.annualCash'] || ''}
          disabled={!canEdit}
        />
        <Select
          label="Frequency of distributions"
          options={distributions}
          control={control}
          name="financialInformation.frequencyDistribution"
          helperText={errors?.financialInformation?.frequencyDistribution?.message}
          error={!!errors?.financialInformation?.frequencyDistribution}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Upfront fee
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Cost of processing the application. Investor fee = Upfront fee + Placement fee.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.upfrontFee'),
            onChange: onChangeHandleDecimal,
          }}
          value={String(values['financialInformation.upfrontFee']) || ''}
          helperText={errors?.financialInformation?.upfrontFee?.message}
          error={!!errors?.financialInformation?.upfrontFee}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Placement fee
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Charges that are assessed for services rendered in a number of business
                  applications. Investor fee = Upfront fee + Placement fee.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.placementFee'),
            onChange: onChangeHandleDecimal,
          }}
          value={String(values['financialInformation.placementFee']) || ''}
          helperText={errors?.financialInformation?.placementFee?.message}
          error={!!errors?.financialInformation?.placementFee}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Asset management fee
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Charge deducted on payouts and project closure for management.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.assetManagementFee'),
            onChange: onChangeHandleDecimal,
          }}
          value={String(values['financialInformation.assetManagementFee']) || ''}
          helperText={errors?.financialInformation?.assetManagementFee?.message}
          error={!!errors?.financialInformation?.assetManagementFee}
          disabled={!canEdit}
        />
        <InputEndAdornment
          label={
            <Typography>
              Investment breakpoint
              <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                <Typography variant="caption" color={Gray[100]}>
                  Percentage of the total targeted return based on confirmed investments. Once it is
                  reached it will pause automatically the project for funds analysis. Also limits
                  maximum investments on Investor app: below breakpoint the maximum to invest is
                  based on total committed money, after breakpoint is based on confirmed money.
                </Typography>
              </InfoIcon>
            </Typography>
          }
          adornment="%"
          inputProps={{
            ...register('financialInformation.investmentBreakpoint'),
            onChange: onChangeHandleDecimal,
          }}
          value={values['financialInformation.investmentBreakpoint'] || ''}
          helperText={errors?.financialInformation?.investmentBreakpoint?.message}
          error={!!errors?.financialInformation?.investmentBreakpoint}
          disabled={!canEdit}
        />
      </Box>
      {showFinancialMetrics && (
        <>
          <Typography variant="h3" mt={6}>
            Actual financial metrics
          </Typography>
          <Typography variant="body1" mt={3}>
            These fields will be updated as the project progresses.
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2,1fr)' }} //eslint-disable-line
            gap={4}
            mt={4}
          >
            <InputEndAdornment
              label={
                <Typography>
                  Total return on investment
                  <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                    <Typography variant="caption" color={Gray[100]}>
                      Exact return on investment. Calculated from payouts.
                    </Typography>
                  </InfoIcon>
                </Typography>
              }
              adornment="%"
              value={payoutsMetrics?.actualReturn}
              disabled
            />
            <InputEndAdornment
              label={
                <Typography>
                  Annual cash on cash return/dividends
                  <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                    <Typography variant="caption" color={Gray[100]}>
                      Exact annual cash on cash return. Calculated from payouts.
                    </Typography>
                  </InfoIcon>
                </Typography>
              }
              adornment="%"
              value={payoutsMetrics?.actualCashOnCash}
              disabled
            />
            <InputEndAdornment
              label={
                <Typography>
                  Net IRR on investment
                  <InfoIcon svgProps={{ height: '15px', width: '15px' }}>
                    <Typography variant="caption" color={Gray[100]}>
                      Exact return on investment.
                    </Typography>
                  </InfoIcon>
                </Typography>
              }
              adornment="%"
              inputProps={{
                ...register('financialInformation.actualAnnualReturn'),
                onChange: onChangeHandleDecimal,
              }}
              value={values['financialInformation.actualAnnualReturn']}
              disabled={!financialRole || status !== PROJECT_STATUSES.PROJECT_COMPLETED}
            />
          </Box>
          {financialRole && status === PROJECT_STATUSES.PROJECT_COMPLETED && (
            <>
              <Grid container columns={{ lg: 10, md: 10, sm: 8, xs: 4 }} direction="row-reverse">
                <Grid item xs={2} mt={{ xs: '2rem' }}>
                  <ButtonSubmit area={projectAreas.updateProjectDetail} variant="contained">
                    Save
                  </ButtonSubmit>
                </Grid>
              </Grid>
            </>
          )}
          <Divider sx={{ mb: 5, mt: 7 }} />
          <Typography variant="h1" mt={6}>
            Financial Reports
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', lg: 'repeat(2,1fr)' }} //eslint-disable-line
            gap={4}
            mt={4}
          >
            <DesktopDatePicker
              label={'Report Date'}
              value={reportDate}
              allowSameDateSelection
              onChange={(newValue: number | null) => setReportDate(newValue ?? 0)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              renderInput={(params: any) => <InputField {...params} />}
              PaperProps={{
                style: {
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: '8px',
                  boxShadow: theme.shadows[2],
                },
              }}
              components={{ OpenPickerIcon: CalendarIcon }}
            />
            <FileUploadButton
              filesTypes=".pdf"
              onUpload={onHandleReport}
              disabled={!reportDate || !isReportableProject}
            />
            <AttachedReports
              reports={reports}
              onRemoveReport={onRemoveReport}
              area={areas.getFinancialReports}
            ></AttachedReports>
          </Box>
        </>
      )}
    </>
  );
};
export { Financial };
