import { Box, Paper, Theme, useMediaQuery } from '@mui/material';
import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { projectAreas } from '../../api/projects';
import { OverlayLoader } from '../../components/OverlayLoader';
import { ProjectHeader } from '../../components/ProjectHeader';
import { ProjectStatusBar } from '../../components/ProjectStatusBar';
import { Tab } from '../../components/Tab';
import { Tabs } from '../../components/Tabs';
import { PROJECT_STATUSES } from '../../helpers/constants';
import { havePermission } from '../../helpers/havePermission';
import { authContext } from '../../hooks/useAuth/authContext';
import { Gray } from '../../theme/tokens/colors'; //eslint-disable-line
import { IProjectDetailProps, ITab } from '.';

const tabsList: ITab[] = [
  {
    alias: 'report',
    justForRoles: ['compliance', 'financial', 'legal', 'superAdmin', 'marketing'],
    label: 'Report',
    notForStatus: [PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.ARCHIVED],
  },
  {
    alias: 'payouts',
    justForRoles: ['compliance', 'superAdmin', 'financial', 'legal', 'marketing'],
    label: 'Payouts',
    notForStatus: [
      PROJECT_STATUSES.ACTIVE,
      PROJECT_STATUSES.DRAFT,
      PROJECT_STATUSES.ARCHIVED,
      PROJECT_STATUSES.PAUSED,
      PROJECT_STATUSES.CANCELED,
      PROJECT_STATUSES.COMPLETED,
      PROJECT_STATUSES.CLOSED,
      PROJECT_STATUSES.CREATING_TOKENS,
    ],
  },
  {
    alias: 'tokens',
    justForRoles: ['compliance', 'financial', 'superAdmin', 'legal', 'marketing'],
    label: 'Tokens',
    notForStatus: [
      PROJECT_STATUSES.ACTIVE,
      PROJECT_STATUSES.DRAFT,
      PROJECT_STATUSES.ARCHIVED,
      PROJECT_STATUSES.PAUSED,
      PROJECT_STATUSES.CANCELED,
      PROJECT_STATUSES.COMPLETED,
      PROJECT_STATUSES.CLOSED,
    ],
  },

  {
    alias: 'commissions',
    justForRoles: ['superAdmin', 'financial', 'legal', 'marketing'],
    label: 'Commissions',
    notForStatus: [PROJECT_STATUSES.ARCHIVED, PROJECT_STATUSES.CANCELED],
  },
  { alias: 'edit', label: 'Basic' },
  { alias: 'edit/financial', label: 'Financial' },
  { alias: 'edit/legal', label: 'Legal' },
];

const ProjectDetail: FC<IProjectDetailProps> = ({
  metrics,
  form,
  reqProjectDetail,
  status,
  category,
  handleStatusChange,
  handleHideProject,
  handlePauseContract,
  contractStatus,
}) => {
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { userData } = useContext(authContext);
  const [tabs, setTabs] = useState<ITab[]>([]);
  const navToDefaultTab = id && (pathname.endsWith(id) || pathname.endsWith(id + '/'));

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    nav(tabs[newValue].alias);
  };

  const getActiveTab = () => {
    const activeIndex = tabs.findIndex((tab) => pathname.endsWith(tab.alias));
    return activeIndex === -1 ? 0 : activeIndex;
  };

  useEffect(() => {
    if (status.status && category) {
      const validTabs = tabsList.filter((tab) =>
        tab.notForStatus ? !tab.notForStatus?.includes(status.status) : true
      );
      const currentTabs = validTabs.filter((tab) =>
        tab.justForRoles
          ? havePermission((userData?.roles as string[]) ?? [], tab.justForRoles)
          : true
      );
      const filterCategory = currentTabs.filter((tab) => {
        if (category === 'DEVELOPMENT' && status.status === PROJECT_STATUSES.TOKENS_DELIVERED) {
          return tab.alias !== 'payouts';
        }
        return true;
      });
      setTabs(filterCategory);
      if (navToDefaultTab) {
        nav(filterCategory[0].alias, { replace: true });
      }
    }
  }, [status.status, userData, category]); //eslint-disable-line

  return (
    <>
      <ProjectHeader
        contractStatus={contractStatus}
        name={form?.projectTitle ?? 'Project detail'}
        projectData={form}
        projectStatus={status.status}
      />

      {status.status && category && (
        <Box mt={5} data-testid={id}>
          <ProjectStatusBar
            status={status.status}
            category={category}
            hidden={status.hidden}
            handleStatusChange={handleStatusChange}
            handleHideProject={handleHideProject}
            handlePauseContract={handlePauseContract}
            metrics={metrics}
            contractStatus={contractStatus}
            projectName={form?.projectTitle ?? ''}
          />
        </Box>
      )}

      <Paper
        sx={{
          bgcolor: 'background.paper',
          border: `1px solid ${Gray[300]}`,
          borderRadius: { sm: 2, xs: 0 },
          boxShadow: 0,
          marginTop: 3,
          mx: { sm: 0, xs: -3 },
          position: 'relative',
        }}
      >
        <Tabs
          value={getActiveTab()}
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          onChange={handleChange}
          aria-label="project details tabs"
        >
          {status.status && tabs.map((tab: ITab) => <Tab key={tab.alias} label={tab.label} />)}
        </Tabs>

        <Box
          sx={{
            minHeight: 200,
            pb: pathname.includes('edit') ? 9 : 3,
            px: { xs: 3, md: 3, lg: pathname.includes('edit') ? 10 : 3 }, //eslint-disable-line
            pt: { xs: 3, md: pathname.includes('edit') ? 7 : 3 }, //eslint-disable-line
          }}
        >
          <Outlet
            context={{
              contractStatus,
              form,
              metrics,
              projectName: form?.projectTitle ?? '',
              reqProjectDetail,
              status: status.status,
            }}
          />
          <OverlayLoader area={projectAreas.getProjectDetail} theme="container" />
        </Box>
      </Paper>
    </>
  );
};

export { ProjectDetail };
