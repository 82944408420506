import { AlertColor } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { IIncompleteDialog as IIncompleteDialogBase } from '../../components/IncompleteDialog';
import { IProjectStatusData } from '../../components/ProjectStatusBar';

const MAX_INVESTMENT_PERCENTAGE_LOANS = 9.99;
const MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME = 100;

interface OBJ {
  [key: string]: string;
}

type TContractStatus =
  | 'active'
  | 'active_loading'
  | 'paused'
  | 'paused_loading'
  | 'destroy_in_progress'
  | 'destroyed';

interface IFinancial {
  actualAnnualReturn?: number;
  annualCash?: number;
  annualInvestment?: number;
  frequencyDistribution?: string;
  maximumInvestmentPercentage?: number;
  minimumInvestment?: number;
  totalReturnInvestment?: number;
  totalTargetedInvestment?: number;
  percentOverage?: number;
  upfrontFee?: number;
  placementFee?: number;
  assetManagementFee?: number;
  investmentBreakpoint?: number;
  financialReports?: IFinancialReport[];
}

interface IFinancialReport {
  date: Date;
  file: File;
}

interface IFinancialReportResponse {
  date: Date;
  url: string;
  fileName: string;
  onRemoveReport: (fileName: string) => MouseEventHandler<HTMLButtonElement> | undefined;
}

interface IBeneficiaryBank {
  accountNumber: string;
  bankAddress: string;
  bankCity: string;
  bankCountry: string;
  bankName: string;
  bankPostalCode: string;
  beneficiaryName: string;
  beneficiaryAddress: string;
  routingNumber: string;
  swiftCode: string;
}

interface IBeneficiaryWallets {
  [key: string]: IBeneficiaryWallet;
}

interface IBeneficiaryWallet {
  address: string;
  currency: string;
  network: string;
}

interface ILegal {
  document?: string;
}

interface IReport {
  averageInvestment: number;
  committedInvestment: number;
  committedPercentageGoal: number;
  committedPercentageRaised: number;
  committedPercentageStretchGoal: number;
  numberOfInvestors: number;
  exceptions: number;
  receivedInvestment: number;
  receivedPercentageGoal: number;
  receivedPercentageRaised: number;
  receivedPercentageStretchGoal: number;
  targetedInvestmentAmount: number;
  targetedInvestmentAmountStretch: number;
  totalMoneyRaised: number;
  whitelistAmount: number;
}

interface IProject {
  projectId?: string;
  projectTitle?: string;
  investmentPeriod?: string;
  status?: IProjectT;
  projectType?: string;
  metrics: IReport;
  closingDate?: Date | string;
  creationDate?: string;
  financialInformation?: IFinancial;
  beneficiaryBank?: IBeneficiaryBank;
  beneficiaryWallets?: IBeneficiaryWallets;
  hidden?: boolean;
  legalInformation?: ILegal;
  startDate?: string;
  category: PROJECT_TYPES;
  isUs: boolean;
  canBeDuplicated?: boolean;
}

type IProjectForm = Pick<
  IProject,
  | 'projectId'
  | 'projectTitle'
  | 'investmentPeriod'
  | 'projectType'
  | 'closingDate'
  | 'creationDate'
  | 'financialInformation'
  | 'beneficiaryBank'
  | 'beneficiaryWallets'
  | 'legalInformation'
  | 'startDate'
  | 'isUs'
  | 'canBeDuplicated'
>;

type IProjectStatus =
  | 'draft'
  | 'active'
  | 'paused'
  | 'canceled'
  | 'completed'
  | 'archived'
  | 'closed'
  | 'creating'
  | 'delivered'
  | 'development_completed'
  | 'project_completed';

enum PROJECT_TYPES {
  DEVELOPMENT = 'DEVELOPMENT',
  INCOME_PRODUCING = 'INCOME_PRODUCING',
  LOANS = 'LOANS',
}

const PROJECT_CATEGORY = new Map([
  ['land', PROJECT_TYPES.DEVELOPMENT],
  ['repositioning_development', PROJECT_TYPES.DEVELOPMENT],
  ['repositioning_income', PROJECT_TYPES.INCOME_PRODUCING],
  ['retail', PROJECT_TYPES.INCOME_PRODUCING],
  ['office', PROJECT_TYPES.INCOME_PRODUCING],
  ['multifamily', PROJECT_TYPES.INCOME_PRODUCING],
  ['industrial', PROJECT_TYPES.INCOME_PRODUCING],
  ['loans', PROJECT_TYPES.LOANS],
]);

const PROJECT_DETAIL_ERRORS: OBJ = {
  MaximumInvestmentLowerThanMinimumAllowedException: 'Maximum invesment lower than minimum allowed',
  MaximumInvestmentPercentageGreaterThanAllowedException:
    'Maximum investment percentage greater than allowed',
  MoneyReceivedGreaterThanThresholdInvestmentBreakpointException:
    'Received money greater or equal than Breakpoint * Stretch Goal',
  MoneyReceivedGreaterThanThresholdMinimumException:
    'Received + Commmitted money greater than Stretch Goal - Minimum Investment',
  TotalInvestmentPerInvestorGreaterThanAllowedException:
    'Total investment per investor exceeds the allowed limit.',
  TotalReceivedInvestmentPerInvestorGreaterThanAllowedException:
    'The project cannot be closed because at least one investor has investments that exceed the allowed maximum, based on all received investments.',
};

interface IProjectOutletContext {
  metrics: IReport;
  form?: IProjectForm;
  reqProjectDetail: () => void;
  status: IProjectStatus;
  setIsDirtyAnnualReturn: Dispatch<SetStateAction<boolean>>;
}

interface IFormOutletContext {
  validStatus: boolean;
  setShowEditButton: Dispatch<SetStateAction<boolean>>;
  basicRole: boolean;
  financialRole: boolean;
  legalRole: boolean;
  contractStatus: TContractStatus;
  projectName: string;
}
interface IToastState {
  message?: string;
  severity?: AlertColor;
  title: string;
}

interface ITabsDisplay {
  basic?: boolean;
  financial?: boolean;
  legal?: boolean;
  report?: boolean;
}

interface IProjectDetailProps {
  handleStatusChange: (status: IProjectStatus) => void;
  handleHideProject: (hidden: boolean) => void;
  handlePauseContract: () => void;
  contractStatus?: TContractStatus;
  status?: IProjectStatusData;
  category?: IProject['category'];
  reqProjectDetail?: () => void;
  metrics: IReport;
  form?: IProjectForm;
}

interface ITab {
  alias: string;
  justForRoles?: string[];
  label: string;
  notForStatus?: string[];
}

type IIncompleteDialog = Omit<IIncompleteDialogBase, 'onClose' | 'title'>;

export {
  IFinancial,
  IFinancialReport,
  IFinancialReportResponse,
  IFormOutletContext,
  IIncompleteDialog,
  ILegal,
  IProject,
  IProjectDetailProps,
  IProjectForm,
  IProjectOutletContext,
  IProjectStatus,
  IReport,
  ITab,
  ITabsDisplay,
  IToastState,
  MAX_INVESTMENT_PERCENTAGE_DEVELOPMENT_INCOME,
  MAX_INVESTMENT_PERCENTAGE_LOANS,
  PROJECT_CATEGORY,
  PROJECT_DETAIL_ERRORS,
  PROJECT_TYPES,
  TContractStatus,
};
